<template>
  <div>
    <HomePageTop/>
    <!--    中国会社-->
    <div style="margin-top: -32px">
      <div class="companyIntroduceHeaderContainer">
        <div class="bg">
          <div class="companyIntroduceHeaderTitle">Rakumartについて</div>
          <div style="width: 100%;display: flex;justify-content: center;flex-direction: column;align-items: center">
            <div class="companyIntroduceHeaderExplain">
              <div style="margin-bottom: 20px">
                Rakumartの運営会社となる「申越網絡科技有限公司」は、アリババとタオバオの発祥地である浙江省杭州市にありま
                す。世界最大の日用雑貨卸売り市場を有する、「百円ショップ」の故郷とも呼ばれる同省義烏市に業務母体を構えており
                ます。現時点の従業員総数は260名に達し、営業面積は20000平米を超えています。
              </div>
              <div>
                Rakumartでは、最先端IT技術の開発・導入により、中国における優良サプライヤーと世界中の中小仕入れ業者との繋
                がりを密接にし、「世界の工場」ならではの優れた商品を世界に流通させ、より透明な情報開示、よりスムーズな業務遂
                行、より 安心、安全、簡単なネット貿易モデルの普及を目標に挙げております。商品リサーチからネット仕入れ、輸出入、
                OEM、検品、在庫管理、国際輸出、海外倉庫までの一貫した完全サポート体系に頼り、物流、 情報流、資金流を一体化と
                する総合的なB2Bクロスオーバー電子ビジネスの開拓者として前向きに邁進してまいります。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="companyIntroduceFooterContainer">
        <div class="container">
          <div class="associationOrSociety">
            <img src="../../assets/foot-img/companyIntroduceIcon.png" alt="" style="margin-right: 37px">中国会社
            <img src="../../assets/foot-img/companyIntroduceIcon.png" alt="" style="margin-left: 37px"></div>
          <div class="flex" style="margin-bottom:202px">
            <div class="transformText">CHINA</div>
            <div style="margin-right: 109px">
              <div class="chinaAssociationOrSocietyTextTitle">中国支社</div>
              <div class="chinaAssociationOrSocietyMessageContainer">
                <div style="margin-bottom: 16px">
                  <div class="label">社名</div>
                  <div class="value">杭州申越网络科技有限公司</div>
                </div>
                <div style="margin-bottom: 16px">
                  <div class="label">所在地</div>
                  <div class="value">浙江省杭州市滨江区网商路699号4-3-002（アリババキャンパス内）</div>
                </div>
                <div style="margin-bottom: 16px">
                  <div class="label">Tel</div>
                  <div class="value">0571-8717-0622</div>
                </div>
                <div>
                  <div class="label">メール</div>
                  <div class="value">services@rakumart.com</div>
                </div>
              </div>
            </div>
            <img alt="" src="../../assets/foot-img/image.png" style="width: 605px;">
          </div>
          <div class="flex" style="position: relative">
            <img src="../../assets/foot-img/companyIntroduce-3.jpg" alt="" style="width:453px;margin-left:52px">
            <div class="newTransformText">CHINA</div>
            <div style="margin-left: 230px">
              <div class="chinaAssociationOrSocietyTextTitle" style="text-align: right">中国本社</div>
              <div class="chinaAssociationOrSocietyMessageContainer newText" style="justify-content: flex-end">
                <div style="margin-bottom: 16px">
                  <div class="label">社名</div>
                  <div class="value">义乌市合道电子商务有限公司</div>
                </div>
                <div style="margin-bottom: 16px">
                  <div class="label">所在地</div>
                  <div class="value">浙江省金华市义乌市 景三路37号2号楼</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FootVue/>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import FootVue from "../../components/foot/Foot.vue";

export default {
  components: {
    HomePageTop,
    FootVue
  },
  data() {
    return {
      clientWidth: 0
    }
  },
  mounted() {
    this.clientWidth = document.body.clientWidth;
  }
}
</script>

<style lang="scss" scoped>
.fontSize26 {
  font-size: 26px;
}

.companyIntroduceHeaderContainer {
  position: relative;
  height: 450px;


  .bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 450px;
    background: url("../../assets/foot-img/companyIntroduce-1.jpg") no-repeat;
    background-size: 100% 100%;
    text-align: center;

    .companyIntroduceHeaderTitle {
      font-weight: bold;
      color: #FFFFFF;
      font-size: 32px;
      text-align: center;
      margin-top: 89px;
    }

    .companyIntroduceHeaderExplain {
      color: #fff;
      margin-top: 75px;
      width: 874px
    }
  }

  width: auto;
  overflow: hidden;
}

.companyIntroduceFooterContainer {
  background-color: #fff;
  margin-bottom: 30px;

  .container {
    background: #fff;
    width: 1103px;
    padding: 89px 0 47px 0;
    margin: 0 auto;
    position: relative;

    .associationOrSociety {
      color: #000000;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      margin-bottom: 100px;
    }

    .transformText {
      opacity: 0.05;
      color: #000000;
      font-weight: bold;
      font-size: 100px;
      transform: rotateZ(450deg);
      position: absolute;
      left: -123px;
      top: 322px;
    }

    .newTransformText {
      opacity: 0.05;
      color: #000000;
      font-weight: bold;
      font-size: 100px;
      transform: rotateZ(450deg);
      position: absolute;
      right: -130px;
      top: 90px;
    }

    .chinaAssociationOrSocietyTextTitle {
      color: #000000;
      font-weight: bold;
      font-size: 26px;
      margin-left: 42px;
      margin-bottom: 36px;
    }


    .chinaAssociationOrSocietyMessageContainer {
      width: 287px;
      height: 259px;
      margin-left: 42px;
    }

    .newText {
      div {
        width: 100%;
        text-align: right;
      }
    }

    .label {
      font-size: 20px;
      color: #000000;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .value {
      color: #666666;
    }
  }
}
</style>
